document.addEventListener('DOMContentLoaded', () => {
  const healthProfile = document.querySelector('.edit_health_profile');

  if (!healthProfile) {
    return;
  }

  const primaryGoal = healthProfile.querySelector('#health_profile_primary_goal') as HTMLSelectElement;
  const weightGoal = healthProfile.querySelector('#health_profile_weight_goal') as HTMLSelectElement;

  primaryGoal.addEventListener('change', () => {
    const weightGoalContainer = healthProfile.querySelector('#health_profile_weight_goal')!.closest('div[class^=col-]');
    const fitnessStyleContainer = healthProfile.querySelector('#health_profile_fitness_goal')!.closest('div[class^=col-]');

    weightGoalContainer!.classList.add('d-none');
    fitnessStyleContainer!.classList.add('d-none');

    if (primaryGoal.value === 'Manage Weight') {
      weightGoalContainer!.classList.remove('d-none');
    } else if (primaryGoal.value === 'Get Fit') {
      fitnessStyleContainer!.classList.remove('d-none');
    }
  });

  weightGoal.addEventListener('change', () => {
    const goalWeightContainer = healthProfile.querySelector('#health_profile_goal_weight')!.closest('div[class^=col-]');

    if (weightGoal.value === 'Maintain') {
      goalWeightContainer!.classList.add('d-none');
    } else {
      goalWeightContainer!.classList.remove('d-none');
    }
  });
});
