import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ProfilePic = ({ image, size = 'small', className = '' }) => {
  const style = {
    backgroundImage: `url(${image})`,
  };
  return <div className={classNames('profile-pic', size, className)} style={style} />;
};

export default ProfilePic;

ProfilePic.propTypes = {
  image: PropTypes.string.isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
};
