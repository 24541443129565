/* eslint-disable no-param-reassign */
const swapForDarkMode = (icon: HTMLLinkElement) => {
  const { darkModeIcon } = icon.dataset;

  if (!darkModeIcon) {
    return;
  }

  icon.dataset.lightModeIcon = icon.href;
  icon.href = darkModeIcon;
};
/* eslint-enable no-param-reassign */

/* eslint-disable no-param-reassign */
const swapForLightMode = (icon: HTMLLinkElement) => {
  const { lightModeIcon } = icon.dataset;

  if (!lightModeIcon) {
    return;
  }

  icon.href = lightModeIcon;
};
/* eslint-enable no-param-reassign */

const swapIcon = (darkModeEnabled: boolean) => {
  const icons: NodeListOf<HTMLLinkElement> = document.querySelectorAll('link[rel~="icon"]');

  if (darkModeEnabled) {
    icons.forEach(swapForDarkMode);
  } else {
    icons.forEach(swapForLightMode);
  }
};

const InitFaviconSwapper = () => {
  const matcher = window.matchMedia('(prefers-color-scheme:dark)');
  swapIcon(matcher.matches);
  matcher.onchange = e => swapIcon(e.matches);
};

export default InitFaviconSwapper;
