import axios from 'axios';
import React from 'react';
import PropTypes from 'prop-types';

import Loader from 'packs/common/Loader';
import Error from 'packs/common/Error';
import Pagination from 'packs/common/Pagination';
import LeaderboardItem from './LeaderboardItem';

export default class Leaderboard extends React.Component {
  constructor(props) {
    super(props);
    this.pages = [];
    this.state = {
      totalCount: 0,
      currentPage: 1,
      userItem: null,
      items: [],
      isLoading: true,
      error: null,
    };
  }

  onPageChange = (page) => {
    if (this.pages[page]) {
      this.setState({ currentPage: page, items: this.pages[page] });
    } else {
      this.fetchPage(page);
    }
  }

  fetchPage = (page) => {
    axios.get(this.apiUrl(page)).then((response) => {
      this.pages[page] = response.data.items;
      this.setState({
        currentPage: page,
        items: this.pages[page],
        totalCount: response.data.total_count,
        userItem: response.data.user_item,
        isLoading: false,
      });
    }).catch((error) => {
      this.setState({ isLoading: false, error });
    });
  }

  apiUrl(page) {
    return `${this.props.url}/results/?group_by=${this.props.groupBy}&sort_by=${this.props.sortBy}&scope=${this.props.scope}&limit=${this.props.perPage}&page=${page}`;
  }

  body() {
    if (this.state.isLoading) {
      return <Loader title="Hang tight while we calculate the data" />;
    }
    if (this.state.error) {
      return <Error message="There was an issue loading the leaderboard. Please check back again later." />;
    }
    if (this.state.totalCount === 0) {
      return <Error message="Leaderboard hasn't built yet. Please check back again later." />;
    }
    return (
      <div>
        {this.state.userItem && (
          <div>
            <LeaderboardItem data={this.state.userItem} isUser />
            <hr className="hr-excerpt" />
          </div>
        )}
        <div>
          {this.state.items.map(item => <LeaderboardItem data={item} key={item.rank} />)}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="leaderboard">
        {this.body()}
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="text-center margin-all">
              <Pagination
                onPageChange={this.onPageChange}
                totalCount={this.state.totalCount}
                currentPage={this.state.currentPage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Leaderboard.propTypes = {
  url: PropTypes.string.isRequired,
  groupBy: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  scope: PropTypes.string.isRequired,
  perPage: PropTypes.number,
};

Leaderboard.defaultProps = {
  perPage: 10,
};
