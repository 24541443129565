/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/bundle and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import PageLoader from 'src/page-loader';
import InitFaviconSwapper from 'src/favicon-swapper';
import { setupInputFocus } from 'src/typeaheads/select2-configs';
import { initSelect2 } from 'src/typeaheads/typeaheads';
import SetupUserContent from 'src/user-content';
import sessionTimeout from 'src/sessionTimeout';

import 'src/date_selects';
import 'src/health_profiles';
import 'src/challenge-calendar';

import './leaderboard';
import './rewards';
import './forms';
import './biometrics';
import './timezone';
import './resources';
import './courses';
import './tracker';
import './common/tooltip';
import './private_challenges';
import './stakes';
import './common/axios';

import { initPortalCalendars } from './common/calendars';

InitFaviconSwapper();
document.addEventListener('DOMContentLoaded', () => {
  initSelect2();
  window.App.initSelect2 = initSelect2;

  setupInputFocus();

  initPortalCalendars();
  PageLoader();
  SetupUserContent();
  sessionTimeout();
});
