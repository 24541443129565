/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import PropTypes from 'prop-types';

import Card from 'packs/common/Card';

import './PostCard.scss';

export default class PostCard extends React.Component {
  flipCard = () => {
    this.card.flipCard();
  }

  hasExcerpt() {
    return !!this.props.excerpt;
  }

  linkTarget() {
    return this.props.open_in_new_tab ? '_blank' : '_self';
  }

  renderBackOfCard() {
    if (this.props.flippable && this.hasExcerpt()) {
      return (
        <div>
          <p>{this.props.excerpt}</p>
          <div className="text-center">
            <a href={this.props.url} target={this.linkTarget()} className="btn btn-sm btn-default">Read more</a>
          </div>
        </div>
      );
    }

    return null;
  }

  renderFlipButton() {
    if (this.props.flippable) {
      return (
        <button
          type="button"
          className="card__control btn btn-sm u-floaty"
          onClick={this.flipCard}
        >
          <strong
            role="button"
            tabIndex="0"
            className="card__flip_trigger text-black"
          >
            More
            <i className="fa fa-info-circle" />
          </strong>
        </button>
      );
    }

    return null;
  }

  render() {
    return (
      <React.Fragment>
        { this.props.recommended && (
          <div className="label-ribbon-stacked label-left">
            <div className="label-ribbon label-ribbon--left label-ribbon--secondary" title="Recommended based on your health profile">
              Recommended <i className="fa fa-thumbs-up" />
            </div>
          </div>
        )}

        <Card
          header={false}
          ref={(card) => { this.card = card; }}
          height="320px"
          front={(
            <div className="card__post">
              {this.renderFlipButton()}
              <a href={this.props.url} target={this.linkTarget()} className="card__image" style={{ backgroundImage: `url('${this.props.image}')` }} aria-label={this.props.title} />
              <a href={this.props.url} target={this.linkTarget()} className="text-black card__title-container" style={{ height: '70px' }}>
                <div className="card__title margin-top-sm">
                  {this.props.title}
                </div>
              </a>
            </div>
          )}
          back={this.renderBackOfCard()}
        />
      </React.Fragment>
    );
  }
}

PostCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  flippable: PropTypes.bool.isRequired,
  open_in_new_tab: PropTypes.bool.isRequired,
  recommended: PropTypes.bool.isRequired,
};
