/* eslint-disable func-names */

import { initSelect2 } from 'src/typeaheads/typeaheads';
import moment from 'moment';

type ChallengeCalendarDocument = Document & {
  $calendar?: any;
  challengeId?: any;
  userId?: any;
  completedDates?: any;
  recordedDates?: any;
  applyCheckMarks?: any;
  calendar?: any;
};

type ChallengeCalendarWindow = Window & {
  App?: any;
};

$(function () {
  const self: ChallengeCalendarDocument = this;

  self.$calendar = $('#challenge-calendar');

  if (self.$calendar.length === 0) {
    return;
  }

  self.challengeId = self.$calendar.data('id');
  self.userId = self.$calendar.data('user');
  self.completedDates = self.$calendar.data('completed').split(',');
  self.recordedDates = self.$calendar.data('recorded').split(',');

  self.applyCheckMarks = function () {
    self.recordedDates.forEach((date) => {
      if (date) {
        const $calendarDay = $(`.calendar-day-${date}`);
        $calendarDay.addClass('recorded');
        if (self.completedDates.indexOf(date) !== -1) {
          $calendarDay.addClass('event');
        }
      }
    });
  };

  self.$calendar.on('click', '#clndr-todos-nav a', function (e) {
    e.preventDefault();

    // move this
    if ($(this).hasClass('prev')) {
      $('.clndr .content').css('margin-top', '+=125px');
    } else {
      $('.clndr .content').css('margin-top', '-=125px');
    }

    // calc after transition is over
    setTimeout(() => {
      const clndrContentHeight = $('.clndr .content').outerHeight();
      const clndrHeight = $('.clndr').outerHeight();
      const clndrViewPortOffset = parseInt($('.clndr .content').css('margin-top'), 10);
      const clndrHeaderHeight = $('.clndr-todo-header').outerHeight();
      const clndrTodosNav = $('#clndr-todos-nav').outerHeight();
      // eslint-disable-next-line max-len
      const outOfView = (clndrContentHeight! + clndrViewPortOffset) - (clndrHeight! - clndrHeaderHeight! - clndrTodosNav!);

      if (outOfView > 0 && clndrViewPortOffset < 0) {
        $('#clndr-todos-nav .prev').css({ width: '50%' });
        $('#clndr-todos-nav .next').css({ width: '50%' });
      } else if (clndrViewPortOffset < 0) {
        $('#clndr-todos-nav .next').css({ width: 0 });
        $('#clndr-todos-nav .prev').css({ width: '100%' });
      } else {
        $('#clndr-todos-nav .prev').css({ width: 0 });
        $('#clndr-todos-nav .next').css({ width: '100%' });
      }
    }, 500);
  });

  self.calendar = self.$calendar.clndr({
    constraints: {
      startDate: self.$calendar.data('start'),
      endDate: self.$calendar.data('end'),
    },
    template: $('#calendar_template').html(),
    events: [],
    showNav: {},
    clickEvents: {
      click(target) {
        if (!$(target.element).hasClass('inactive')) {
          // eslint-disable-next-line no-underscore-dangle
          let path = `/challenges/${self.challengeId}/todos?date=${target.date._i}`;

          if (self.userId) {
            path += `&user_id=${self.userId}`;
          }

          $('.todos .content').load(path, function () {
            if (target.date > moment()) {
              $('.todos .content a.todo-item-single').addClass('disabled');
            }

            $('.todos .content form').each((i, e) => {
              $(e).append($('<input type="hidden" name="calendar" value="true" />'));
            });

            const initClndrHeight = $('.clndr').height();
            const initClndrHeaderHeight = $('.clndr-todo-header').outerHeight();
            const initClndrContentHeight = $('.clndr .content').outerHeight();

            $(this).parents('.todos').addClass('active');
            // eslint-disable-next-line no-underscore-dangle
            $(this).parents('.todos').find('.clndr-todo-header').html(`<h4 class="clndr-todo-title">My To-Dos</h4><p class="clndr-todo-date">for ${moment(target.date._i).format('dddd, MMMM D')}</p>`);

            $('.todo-item', $(this)).parents('a').each((i, e) => {
              const identifier = `[data-remodal-id=${$(e).attr('data-remodal-target')}]`;
              // @ts-ignore
              $(identifier).remodal();
            });

            $('.todo-item-single.disabled').each(function () {
              $(this).find('.todo-item-text').append('<span class="text-danger">Recording disabled</span>');
            });

            if ((initClndrHeaderHeight! + initClndrContentHeight!) > initClndrHeight!) {
              $('#clndr-todos-nav').addClass('active');
            } else {
              $('#clndr-todos-nav').removeClass('active');
            }

            initSelect2();

            (window as ChallengeCalendarWindow).App.stats.initAll();
            (window as ChallengeCalendarWindow).App.timeline.initAll();
          });
        }

        $('.todos .close-btn').on('click', () => {
          $('.todos').removeClass('active');
        });
      },

      nextMonth: self.applyCheckMarks,
      previousMonth: self.applyCheckMarks,
    },
    ready: self.applyCheckMarks,
  });
});
