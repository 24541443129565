import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import classNames from 'classnames';

import StakeCard from './StakeCard';
import StakeCreate from './StakeCreate';
import './stakes.scss';

const Stakes = (props) => {
  const defaultData = {
    receiver_id: '',
    description: '',
    leaderboard: '',
    errors: [],
  };

  const [stakes, setStakes] = useState(props.stakes);
  const [creating, setCreating] = useState(false);
  const [buttonText, setButtonText] = useState('Create Stake');
  const [data, setData] = useState(defaultData);
  const [resetSearch, setResetSearch] = useState(false);

  const toggleCreate = () => {
    if (creating === false) {
      setButtonText('Cancel');
    } else {
      setResetSearch(true);
      setData(defaultData);
      setButtonText('Create Stake');
    }

    setCreating(!creating);
  };

  const renderStakes = () => {
    if (stakes.length === 0) {
      return 'No Stakes Found';
    }

    return stakes.map(stake => (
      <div key={stake.id} id={stake.id}>
        <StakeCard
          {...stake}
          showButtons={!stake.owner}
          stakes={stakes}
          setStakes={setStakes}
        />
      </div>
    ));
  };

  const submit = () => {
    axios.post(`/api/v2/challenges/${props.challengeId}/stakes`, data)
      .then((response) => {
        setStakes(() => [response.data, ...stakes]);
        toggleCreate();
      }).catch((error) => {
        const copy = {
          ...data,
          errors: error.response.data.errors,
        };
        setData(copy);
      });
  };

  const showErrors = () => data.errors.length > 0 && (
    <div className="alert alert-danger">
      {data.errors.map((e, key) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={key}>{e}</li>
      )) }
    </div>
  );

  const createStakes = () => {
    if (props.allowCreate) {
      return (
        <React.Fragment>
          <div className={classNames({ 'd-none': !creating })}>
            <StakeCreate
              challengeId={props.challengeId}
              challengeVocab={props.challengeVocab}
              leaderboards={props.leaderboards}
              data={data}
              setData={setData}
              resetSearch={resetSearch}
              setResetSearch={setResetSearch}
            />
          </div>

          <div className="d-flex justify-content-end">
            <button
              type="button"
              onClick={toggleCreate}
              className="btn btn-default"
              data-test="actionButton"
            >
              {buttonText}
            </button>

            <button
              type="button"
              onClick={submit}
              className={classNames('btn btn-primary ms-2', { 'd-none disabled': !creating })}
            >
              Submit
            </button>
          </div>

          <div className="mt-3">
            {showErrors()}
          </div>
        </React.Fragment>
      );
    }

    return null;
  };

  return (
    <div className="stakes card-body">
      {createStakes()}

      <div className="stakes-list">
        {renderStakes()}
      </div>
    </div>
  );
};

export default Stakes;

Stakes.propTypes = {
  challengeId: PropTypes.number.isRequired,
  challengeVocab: PropTypes.string.isRequired,
  stakes: PropTypes.array.isRequired,
  leaderboards: PropTypes.array.isRequired,
  allowCreate: PropTypes.bool.isRequired,
};
