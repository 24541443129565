import flatpickr from 'flatpickr';

function initDatefilter() {
  flatpickr('.datefilter', {
    allowInput: true,
  });
}

function initDatepicker() {
  flatpickr('.datepicker', {
    allowInput: true,
    onReady(_selectedDates, _dateStr, instance) {
      if (!instance.input.value) {
        this.setDate('today');
      }
    },
  });
}

function initDatetimePicker() {
  flatpickr('.datetime-picker', {
    allowInput: true,
    enableTime: true,
  });
}

function initTimetraveler() {
  flatpickr('.timetraveler', {
    allowInput: true,
    enableTime: true,
    dateFormat: 'F j, Y h:i K',
    wrap: true,
  });
}

export function initPortalCalendars() {
  initDatefilter();
  initDatepicker();
  initTimetraveler();
}

export function initAdminCalendars() {
  initDatefilter();
  initDatepicker();
  initDatetimePicker();
}
