import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ProfilePic from 'packs/common/ProfilePic';

export default class LeaderboardItem extends React.Component {
  isLeader() {
    return this.props.data.rank === 1;
  }

  render() {
    return (
      <div className={classNames('leaderboard-ranking', { 'is-user': this.props.isUser })}>
        <div className="ranking-left d-none">
          <div className="profile-pic-wrap small">
            {this.isLeader() && (
              <div className="rankicon">
                <span className="fa-stack">
                  <i className="fa fa-circle fa-stack-2x text-secondary" />
                  <i className="po8 po8-leader fa-stack-1x fa-inverse" />
                </span>
              </div>
            )}
            <ProfilePic image={this.props.data.image} />
          </div>
        </div>
        <div className="ranking-body">
          <h4 className="ranking-title">
            {
              this.props.data.link ?
                <a href={this.props.data.link}>{this.props.data.name}</a> :
                this.props.data.name
            }
            <small className="text-muted">{this.props.data.secondary_name}</small>
          </h4>
          <div className="user-stats">
            {this.props.data.stats && this.props.data.stats.map(stat => (
              <small key={stat.unit} className="user-stat-item">
                <strong>{stat.value}</strong>
                &nbsp;{stat.unit}
              </small>
            ))}
          </div>
        </div>
        {this.props.data.rank && (
          <div className="ranking-right">
            <span className="ranking-rank">{this.props.data.rank}</span>
            <span className="ranking-rank-label">rank</span>
          </div>
        )}
      </div>
    );
  }
}

LeaderboardItem.propTypes = {
  isUser: PropTypes.bool,
  data: PropTypes.object.isRequired,
};

LeaderboardItem.defaultProps = {
  isUser: false,
};
