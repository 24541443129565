import React from 'react';
import PropTypes from 'prop-types';

import UserSearch from 'packs/common/UserSearch';
import './stakes.scss';

const StakeCreate = (props) => {
  const handleInputChange = (name, value) => {
    const copy = {
      ...props.data,
      [name]: value,
    };
    props.setData(copy);
  };

  const handleUserChange = (selectedOption) => {
    const copy = {
      ...props.data,
      receiver_id: selectedOption.target.value.id,
    };
    props.setData(copy);
  };

  return (
    <div className="stake-create">
      <h4 className="title border-bottom">Create a Stake</h4>
      Stakes are intended to be a tool to enhance intrinsic motivation
      and promote the socio-competitive potential of wellbeing activities.
      In no way is this tool intended or allowed for the use of gambling.

      <hr />

      <div>
        <label htmlFor="user-search">Who are you challenging?</label>
        <UserSearch
          id="user-search"
          url={`/api/v2/challenges/${props.challengeId}/users`}
          name="receiver"
          onChange={handleUserChange}
          users={[]}
          displaySelectedValues
          resetSearch={props.resetSearch}
          setResetSearch={props.setResetSearch}
        />
        <span className="help-block">
          Only users who are enrolled in this {props.challengeVocab} will appear in search results.
        </span>
      </div>

      <hr />

      <div>
        <label htmlFor="leaderboard-select">What&apos;s being compared?</label>
        <select
          id="leaderboard-select"
          className="form-control"
          name="leaderboard"
          onChange={(e) => { handleInputChange(e.currentTarget.name, e.currentTarget.value); }}
          style={{ borderRadius: '5px' }}
          value={props.data.leaderboard}
        >
          <option>-- Select --</option>
          {props.leaderboards.map(leaderboard => (
            <option
              key={leaderboard.key}
              id={leaderboard.key}
              value={leaderboard.key}
              label={leaderboard.label}
            >
              {leaderboard.label}
            </option>
          ))}
        </select>
      </div>

      <hr />

      <div>
        <label htmlFor="stake-description">What&apos;s at stake?</label>
        <input
          id="stake-description"
          className="form-control"
          name="description"
          onChange={(e) => { handleInputChange(e.currentTarget.name, e.currentTarget.value); }}
          type="text"
          placeholder="Free lunch for the winner, bragging rights, etc."
          value={props.data.description}
          maxLength="64"
          required
        />
      </div>

      <hr />
    </div>
  );
};

export default StakeCreate;

StakeCreate.propTypes = {
  challengeId: PropTypes.number.isRequired,
  challengeVocab: PropTypes.string.isRequired,
  leaderboards: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  resetSearch: PropTypes.bool,
  setResetSearch: PropTypes.func,
};

StakeCreate.defaultProps = {
  resetSearch: false,
  setResetSearch: null,
};
