import Swal from 'sweetalert2';

const mixin = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-danger me-1',
    cancelButton: 'btn btn-default ms-1',
  },
  buttonsStyling: false,
});

export default function ActionWarning(title, text, button = 'Delete') {
  return mixin.fire({
    title,
    text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    confirmButtonText: button,
  });
}
