/* eslint-env jquery */

import flatpickr from 'flatpickr';
import moment from 'moment';

document.addEventListener('DOMContentLoaded', () => {
  const $filterDate = $('.js-filter');
  let readableDate = moment().format('dddd, MMMM D, YYYY');

  const calculateCalories = () => {
    let value = 0;
    $('#nutritionLogTable .calories').each(function () {
      const calories = parseInt($(this).text(), 10);
      if (calories) value += calories;
    });

    return value;
  };

  const calculateFitnessMinutes = () => {
    let value = 0;
    $('#fitnessLogTable .fitness-duration').each(function () {
      const duration = parseInt($(this).text(), 10);
      if (duration) value += duration;
    });

    return value;
  };

  const calculateAverageWeight = () => {
    let value = 0;
    const weightsCount = $('#weightLogTable .weight').length;
    $('#weightLogTable .weight').each(function () {
      value += parseFloat($(this).text(), 10);
    });

    if (weightsCount === 0) {
      return value;
    }
    return (value / weightsCount).toFixed(2);
  };

  const calculateSleepTime = () => {
    let value = 0.0;
    $('#sleepLogTable .sleep-time').each(function () {
      const hours = parseFloat($(this).text(), 10);
      if (hours) value += hours;
    });

    return value;
  };

  const calculateSteps = () => {
    let value = 0;
    $('#routineStepsLog .steps').each(function () {
      const steps = parseInt($(this).text(), 10);
      if (steps) value += steps;
    });

    return value;
  };

  const calculateWater = () => {
    let value = 0;
    $('#routineWaterLogTable .water').each(function () {
      const water = parseInt($(this).text(), 10);
      if (water) value += water;
    });

    return value;
  };

  flatpickr('.tracker-datepicker', {
    defaultDate: 'today',
    disableMobile: 'true',
    maxDate: 'today',
    onChange(selectedDates) {
      const selectedDate = moment(selectedDates[0]).format('YYYY-MM-DD');
      readableDate = moment(selectedDates[0]).format('dddd, MMMM D, YYYY');
      $('.tracker-datepicker span').text(readableDate);

      $('.loading-modal').show().find('.center').html('<i class="fa fa-spinner fa-spin fa-3x"></i>');
      $.each($filterDate.data('models'), (model, path) => {
        let formattedPath = path;
        if (path.indexOf('?') === -1) {
          formattedPath += '?';
        } else {
          formattedPath += '&';
        }

        $(`#${model}Log`).load(`${formattedPath}date=${selectedDate}`, (response, status, xhr) => {
          if (status === 'error') {
            const msg = 'Sorry but there was an error: ';
            $('.loading-modal .center').html(`${msg + xhr.status} ${xhr.statusText}`);
            return false;
          }

          $('#tracker [id$="_timestamp"]').val(selectedDate);
          $('#tracker [id$="_source_name"]').val('Tracker');
          $('.js-helper-text').html(`Recording for ${readableDate}`);
          $('.js-total-calories .stat-value').text(calculateCalories());
          $('.js-fitness-minutes .stat-value').text(calculateFitnessMinutes());
          $('.js-daily-weight .stat-value').text(calculateAverageWeight());
          $('.js-sleep-time .stat-value').text(calculateSleepTime());
          $('.js-total-steps .stat-value').text(calculateSteps());
          $('.js-total-water .stat-value').text(calculateWater());
          $('.loading-modal').hide();
          $('[data-remodal-id]').remodal();

          return true;
        });
      });
    },
  });

  $('.tracker-form').on('ajax:success', function () {
    $(this).find('input:not([id$=_timestamp], [id$=_source_name], [type=submit])').val('');
    $(this).find('select').prop('selectedIndex', 0).trigger('change');
    $('#sleep_start_time_hour', $(this)).prop('selectedIndex', 7);
    $('#sleep_start_time_ampm', $(this)).prop('selectedIndex', 1);
    $('#sleep_end_time_hour', $(this)).prop('selectedIndex', 6);
  });

  $('.js-total-calories .stat-value').text(calculateCalories());
  $('.js-fitness-minutes .stat-value').text(calculateFitnessMinutes());
  $('.js-daily-weight .stat-value').text(calculateAverageWeight());
  $('.js-sleep-time .stat-value').text(calculateSleepTime());
  $('.js-total-steps .stat-value').text(calculateSteps());
  $('.js-total-water .stat-value').text(calculateWater());

  // The below is needed in order to make the #calculate methods available to UJS
  window.calculateCalories = calculateCalories;
  window.calculateFitnessMinutes = calculateFitnessMinutes;
  window.calculateAverageWeight = calculateAverageWeight;
  window.calculateSleepTime = calculateSleepTime;
  window.calculateSteps = calculateSteps;
  window.calculateWater = calculateWater;
});
