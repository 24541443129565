/* eslint-env jquery */
import {
  ClientSelectConfig, ClientSelectMultiConfig, StandardConfig,
  UserSelectConfig, ProgramChallengeSelectConfig,
} from './select2-configs';

export const initSelect2 = (isAdmin = false) => {
  const metaTag: HTMLElement | null = document.querySelector('meta[name="redesign"]');
  let theme = 'bootstrap-5';
  if (metaTag instanceof HTMLMetaElement && !isAdmin) {
    theme = metaTag?.content ? 'navigate' : 'bootstrap-5';
  }

  $('select[data-tags=true], select.select2').select2({ ...StandardConfig, theme });

  $('select[data-tags=true].no-create').select2({
    ...StandardConfig,
    theme,
    createTag() {
      return null;
    },
  });

  $('select.filter').select2({
    ...StandardConfig,
    theme,
    containerCssClass: 'filter',
  });
};

export const initAdminSelect2 = () => {
  initSelect2(true);

  $('.js-client-select').select2(ClientSelectConfig);
  $('.js-client-select-multi').select2(ClientSelectMultiConfig);
  $('.js-user-select').select2(UserSelectConfig);
  $('.js-challenges-select-async').select2(ProgramChallengeSelectConfig);
};
