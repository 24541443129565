const PageLoader = async () => {
  const { controller, action: bodyAction } = document.body.dataset;
  const regexp = new RegExp(`^${controller}-`);
  const action = bodyAction?.replace(regexp, '');
  let module;

  try {
    module = await import(`src/pages/${controller}/${action}`);
  } catch (err) {
    if (err.code !== 'MODULE_NOT_FOUND') {
      console.error('Failed to load javascript for:', controller);
      throw err;
    }
  }

  if (typeof module?.default === 'function') {
    module.default();
  }
};

export default PageLoader;
