import React from 'react';
import { createRoot } from 'react-dom/client';
import LeaderboardSelect from 'src/leaderboard/LeaderboardSelect';

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('leaderboard');

  if (container) {
    const url = container.attributes['data-url'].value;
    const root = createRoot(container);
    root.render(<LeaderboardSelect url={url} />);
  }
});
