const setupDateSelects = (dateSelects: Element) => {
  const yearSelect: HTMLSelectElement | null = dateSelects.querySelector('select[name*="(1i)"]');
  const monthSelect: HTMLSelectElement | null = dateSelects.querySelector('select[name*="(2i)"]');
  const daySelect: HTMLSelectElement | null = dateSelects.querySelector('select[name*="(3i)"]');

  const setRequiredInputs = () => {
    const hasYear: boolean = !!yearSelect?.value;
    const hasMonth: boolean = !!monthSelect?.value;
    const hasDay: boolean = !!daySelect?.value;

    if (hasYear || hasMonth || hasDay) {
      yearSelect!.required = true;
      monthSelect!.required = true;
      daySelect!.required = true;
    } else {
      yearSelect!.required = false;
      monthSelect!.required = false;
      daySelect!.required = false;
    }
  };

  yearSelect?.addEventListener('change', setRequiredInputs);
  monthSelect?.addEventListener('change', setRequiredInputs);
  daySelect?.addEventListener('change', setRequiredInputs);
};

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('[data-js-date-selects]').forEach(setupDateSelects);
});
