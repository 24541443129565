import axios from 'axios';
import React from 'react';
import PropTypes from 'prop-types';
import Error from 'packs/common/Error';
import Leaderboard from './Leaderboard';

export default class LeaderboardSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '', error: null, leaderboards: [] };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    axios.get(this.props.url).then((response) => {
      this.setState({ leaderboards: response.data });
    }).catch(() => {
      this.setState({ error: 'There was a problem loading the leaderboards.' });
    });
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  currentLeaderboard() {
    return this.state.leaderboards.find(l => l.title === this.state.value);
  }

  render() {
    if (this.state.error) {
      return <Error message={this.state.error} />;
    }
    return (
      <div>
        <div id="leaderboard-content" className="card-body">
          <div className="form-group mb-3">
            <label htmlFor="leaderboard_select">
              <small>Select a leaderboard:</small>
            </label>
            <span id="leaderboardNav">
              <select value={this.state.value} onChange={this.handleChange} className="form-control" id="leaderboard_select">
                <option value="">-- Please select to view --</option>
                {this.state.leaderboards.map(leaderboard => (
                  <option key={leaderboard.title} value={leaderboard.title}>{leaderboard.title}</option>
                ))}
              </select>
            </span>
            <hr />
          </div>

          {this.state.value !== '' && (
            <Leaderboard
              id={this.state.value}
              url={this.props.url}
              key={this.state.value}
              groupBy={this.currentLeaderboard().group_by}
              sortBy={this.currentLeaderboard().sort_by}
              scope={this.currentLeaderboard().scope}
            />
          )}
        </div>
      </div>
    );
  }
}

LeaderboardSelect.propTypes = {
  url: PropTypes.string.isRequired,
};
