import React from 'react';
import PropTypes from 'prop-types';

import PostCard from './PostCard';

const PostsLayout = props => (
  <div className="row margin-bottom">
    {props.posts.map(post => (
      <div key={post.title} className="col-md-4 col-sm-6 position-relative">
        <PostCard {...post} flippable={props.flippable} />
      </div>
    ))}
  </div>
);

export default PostsLayout;

PostsLayout.propTypes = {
  posts: PropTypes.array.isRequired,
  flippable: PropTypes.bool,
};

PostsLayout.defaultProps = {
  flippable: false,
};
