import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Loader = (props) => {
  const classes = classNames({ 'text-center': props.centered }, 'margin-all');
  return (
    <div className={classes}>
      <h5>
        <i className="fa fa-circle-o-notch fa-spin fa-fw" />
        {props.title}
      </h5>
    </div>
  );
};

export default Loader;

Loader.propTypes = {
  title: PropTypes.string,
  centered: PropTypes.bool,
};

Loader.defaultProps = {
  title: 'Loading...',
  centered: true,
};
