import React from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import axios from 'axios';
import classNames from 'classnames';

import './stakes.scss';
import ActionWarning from 'packs/common/ActionWarning';

const StakeDetails = (props) => {
  const showButtons = props.showButtons && props.status === 'invited';

  const statusText = () => {
    if (props.status === 'accepted') {
      return props.leader;
    }

    if (props.status === 'declined') {
      return 'This stake has been declined.';
    }

    if (props.status === 'invited' && !showButtons) {
      return 'This stake has not been accepted.';
    }

    return null;
  };

  const respond = (e) => {
    const accept = e.currentTarget.classList.contains('accept-stake');

    axios.post(`/api/v2/challenges/${props.challenge_id}/stakes/${props.id}`, { accept })
      .then((response) => {
        props.setStatus(accept ? 'accepted' : 'declined');

        if (!accept) {
          props.setStakes(props.stakes.filter(stake => stake.id !== response.data.id));
        }
      });
  };

  const destroy = () => {
    const title = 'Confirm Stake Deletion';
    const text = 'Are you sure you want to delete this stake?';

    ActionWarning(title, text).then((result) => {
      if (result.value) {
        axios.delete(`/api/v2/challenges/${props.challenge_id}/stakes/${props.id}`)
          .then(() => {
            props.setStakes(props.stakes.filter(stake => stake.id !== props.id));
          });

        Swal.fire(
          'Deleted!',
          'Stake has been deleted.',
          'success',
        );
      }
    });
  };

  return (
    <div className="col-6">
      <div className="stake-col">
        <h2 className="m-0 text-center">{props.description}</h2>

        <div className={classNames({ 'text-danger': props.status === 'declined' })}>
          <strong>{statusText()}</strong>
        </div>
        <button
          type="button"
          onClick={destroy}
          className={classNames('btn btn-outline-dark btn-sm mt-2', { 'd-none disabled': !props.owner })}
        >
          Delete Stake
        </button>

        <div className={classNames('stake-responses mt-2', { 'd-none disabled': !showButtons })}>
          <button
            type="button"
            onClick={respond}
            className={classNames('btn btn-danger btn-sm decline-stake')}
          >
            Decline
          </button>

          <button
            type="button"
            onClick={respond}
            className={classNames('btn btn-primary btn-sm ms-2 accept-stake')}
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default StakeDetails;

StakeDetails.propTypes = {
  challenge_id: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired,
  showButtons: PropTypes.bool.isRequired,
  stakes: PropTypes.array.isRequired,
  setStakes: PropTypes.func.isRequired,
  leader: PropTypes.string.isRequired,
  owner: PropTypes.bool.isRequired,
};
