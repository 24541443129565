const timeoutWarning = () => {
  $('[data-remodal-id="session-timeout-message"]').removeClass('d-none');
  $('[data-remodal-id="session-timeout-message"]').remodal().open();
};

const pageRefresh = () => {
  window.location.href = window.location.href.split('#')[0];
};

const oneSecond = 1000;
const oneMinute = 60 * oneSecond;
const fiveMinutes = 5 * oneMinute;

export default () => {
  const element = $('#timeout-modal');
  const clientValue = element.data('timeout');

  if (clientValue) {
    const timeout = clientValue * oneSecond;
    let warning = timeout - fiveMinutes;
    if (warning < 250) { warning = 250; }

    let refreshTimeout;
    let warningTimeout;

    const refreshTimeouts = () => {
      if (warningTimeout) { clearTimeout(warningTimeout); }
      warningTimeout = setTimeout(timeoutWarning, warning);
      sessionStorage.setItem('warning-timeout', warningTimeout);

      if (refreshTimeout) { clearTimeout(refreshTimeout); }
      refreshTimeout = setTimeout(pageRefresh, timeout);
      sessionStorage.setItem('refresh-timeout', refreshTimeout);
    };

    const refreshSession = () => {
      $.ajax({
        url: '/sessions/refresh',
        method: 'GET',
        success: refreshTimeouts,
      });
    };

    refreshTimeouts();

    $(document).on('confirmation', '#timeout-modal', refreshSession);
  }
};
