const makeTablesResponsive = (table: HTMLTableElement) => {
  const responsiveClass = 'table-responsive';

  if (!table.parentElement || table.parentElement.classList.contains(responsiveClass)) {
    return;
  }

  const wrapper = document.createElement('div');
  wrapper.classList.add(responsiveClass);

  table.parentElement.insertBefore(wrapper, table);
  wrapper.appendChild(table);
};

const UserContent = () => {
  document.querySelectorAll('[data-user-content]').forEach((userContentContainer) => {
    userContentContainer.querySelectorAll('table').forEach(makeTablesResponsive);
  });
};

export default UserContent;
