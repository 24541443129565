import React from 'react';
import { createRoot } from 'react-dom/client';

import Form from 'src/forms/Form';
import FormResponse from 'src/forms/FormResponse';

document.addEventListener('DOMContentLoaded', () => {
  const formElements = document.querySelectorAll('[data-react="Form"]');
  [...formElements].forEach((formElement) => {
    const root = createRoot(formElement);
    const props = JSON.parse(formElement.getAttribute('data-props'));
    root.render(<Form {...props} />);
  });

  const responseElements = document.querySelectorAll('[data-react="FormResponse"]');
  [...responseElements].forEach((responseElement) => {
    const root = createRoot(responseElement);
    const props = JSON.parse(responseElement.getAttribute('data-props'));
    root.render(<FormResponse {...props} />);
  });
});
