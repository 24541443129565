import React, { useState } from 'react';
import PropTypes from 'prop-types';

import StakeParticipant from './StakeParticipant';
import StakeDetails from './StakeDetails';

const StakeCard = (props) => {
  const [status, setStatus] = useState(props.status);

  return (
    <div className="stake-card row g-0 leaderboard-ranking mb-2">
      <StakeParticipant {...props.sender} status={status} />
      <StakeDetails {...props} status={status} setStatus={setStatus} />
      <StakeParticipant {...props.receiver} status={status} />
    </div>
  );
};

export default StakeCard;

StakeCard.propTypes = {
  sender: PropTypes.object.isRequired,
  receiver: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
};
