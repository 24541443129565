import axios from 'axios';
import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'packs/common/Loader';
import Error from 'packs/common/Error';

export default class Balance extends React.Component {
  constructor(props) {
    super(props);
    this.state = { balance: null, error: null, isLoading: true };
  }

  componentDidMount() {
    axios.get(this.props.url).then((response) => {
      this.setState({ balance: response.data.balance, isLoading: false });
    }).catch((error) => {
      this.setState({ error: error.toString(), isLoading: false });
    });
  }

  render() {
    if (this.state.isLoading) {
      return <Loader title={null} centered={false} />;
    }
    if (this.state.error) {
      return <Error message="There was a problem loading your balance." />;
    }
    return (
      <span>
        Current balance: {this.state.balance}
      </span>
    );
  }
}

Balance.propTypes = {
  url: PropTypes.string.isRequired,
};
