import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ProfilePic from 'packs/common/ProfilePic';
import './stakes.scss';

const StakeParticipant = props => (
  <div className="col-3">
    <div className="stake-col img-wrap-profile icon">
      <ProfilePic image={props.image} size="medium" />
      <div>{props.name}</div>
      <div className={classNames({ 'd-none': props.status !== 'accepted' })}>
        {props.stats.display_value} {props.stats.label}
      </div>
    </div>
  </div>
);

export default StakeParticipant;

StakeParticipant.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  stats: PropTypes.object.isRequired,
};
