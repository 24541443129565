import React from 'react';
import { createRoot } from 'react-dom/client';
import Balance from 'src/rewards/Balance';

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('rewards-balance');

  if (container) {
    const url = container.getAttribute('data-url');
    const root = createRoot(container);
    root.render(<Balance url={url} />);
  }
});
